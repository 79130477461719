<template>
<div>
    <div id="contact-container">
        <div id="instruction">
            <span class="highlight">ご依頼</span>の際に<br>
            下記の内容をご記入して<br class="tablet-hidden">
            お願いいたします
        </div>
        <div id="inputs-container">
            <input id="name" placeholder="お名前" class="card hover-highlight" v-model="name">
            <input id="mail" placeholder="メール" class="card hover-highlight" v-model="mail">
            <input id="subject" placeholder="件名" class="card hover-highlight" v-model="subject">
            <textarea id="message" placeholder="メッセージ" class="card hover-highlight" v-model="message"></textarea>
            <div id="send-btn" :class="{card: true, disabled: !canSend}" @click="send">
                <div>送信</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

export default {
    name: 'contact-page',
    title: 'Contact',
    data() {
        return {
            name: '',
            mail: '',
            subject: '',
            message: ''
        }
    },
    components: {
    },
    computed: {
        canSend() {
            return this.name.length > 0 && this.mail.length > 0 && this.subject.length > 0 && this.message.length > 0
        }
    },
    methods: {
        send() {
            if (!this.canSend)
                return;
            this.name = ''
            this.mail = ''
            this.subject = ''
            this.message = ''
            alert('Sent!')
        }
    }
}
</script>

<style lang="scss" scoped>

#contact-container {
    margin: $mobileContainerMargins;

    input,textarea {
        font-family: 'Hiragino Sans';
        display: block;
        margin: 8vw 0;
        width: 72vw;
        height: 5vw;
        padding: 2.5vw 4vw;
        font-size: 1em;
        border-radius: 1000px;
        transition: border-color 0.3s, transform 0.3s;
    }

    input:focus,textarea:focus {
        border-color: $highlightColor;
        transform: scale(1.03);
    }

    #message {
        height: 35vw;
        border-radius: 2.5vh;
    }

    #send-btn {
        margin: 8vw auto;
        width: 32vw;
        height: 5.5vw;
        padding: 2vw 4vw;
        font-size: 1em;
        border-radius: 1000px;
        text-align: center;
        display: flex;
        border-color: $highlightColor;
        box-shadow: none;
        color: $highlightColor;
        cursor: pointer;
        transition: border-color 0.3s, transform 0.3s, color 0.3s;

        div {
            margin: auto;
        }
    }
}

.highlight {
    color: $highlightColor;
    display: inline;
}

.disabled {
    border-color: #aaaaaa !important;
    box-shadow: none !important;
    color: #999999 !important;
    cursor: not-allowed !important;
}

@media screen and (min-width: $tabletWidth) {
    .tablet-hidden {
        display: none;
    }

    #contact-container {
        margin: $tabletContainerMargins;
    }
    #contact-container #inputs-container {
        margin-top: 2vw;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-size: 0.8em;

        input,textarea {
            font-weight: 600;
            border-radius: 25px;
            height: 70px;
            margin: 1vw 0;
            padding: 0 0 0 1.5%;
        }

        input::placeholder,textarea::placeholder {
            color: #999999;
        }

        #name {
            order: 0;
            width: 30%;
        }

        #mail {
            order: 1;
            width: 30%;
            margin-left: 4%;
        }

        #send-btn {
            order: 2;
            width: 15vw;
            height: 70px;
            margin: 0 0 0 4%;
            padding: 0;
        }

        #subject {
            order: 3;
            width: 90%;
        }

        #message {
            order: 4;
            width: 89%;
            padding: 1.2%;
            height: 14ch;
        }
    }
}
</style>